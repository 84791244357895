<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="角色名称" prop="authUserName">
                    <el-input v-model="searchForm.name" style="width: 220px;" clearable
                              placeholder="请填写角色名称"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div class="fill-btn" @click="handSearch">查询</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
                    <div class="fill-btn" @click="handShowAdd">新增</div>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text"></div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="name" label="角色名称" min-width="120px"></el-table-column>
                <el-table-column prop="created_at" min-width="160px" label="创建时间"></el-table-column>
                <el-table-column width="200" fixed="right">
                    <template slot-scope="{row}">
                        <div class="btn-content">

                            <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                       @click="handShowEdit(row)"></el-button>
                            <el-button class="el-icon-delete cur-p" type="primary" size="mini"
                                       @click="roleDel(row)"></el-button>
                            <el-button class="el-icon cur-p" type="success" size="mini"
                                       @click="handGiveAuth(row)">赋权限</el-button>

                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>
        <HgDialog title="新增角色" :width="500" :maskClose="false" v-model="showAdd">
            <el-form class="dialog-form-content" :model="form" :rules="getRules" ref="editForm" label-width="120px">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="fill-btn" v-load="editing" @click="roleAdd">保存</div>
                </el-form-item>
            </el-form>
        </HgDialog>

        <HgDialog title="编辑角色" :width="500" :maskClose="false" v-model="showEdit">
            <el-form class="dialog-form-content" :model="form" :rules="getRules" ref="editForm" label-width="120px">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="fill-btn" v-load="editing" @click="roleEdit">保存</div>
                </el-form-item>
            </el-form>
        </HgDialog>

        <HgDialog title="赋权限" :width="500" :maskClose="false" v-model="giveAuth">
            <div class="dialog-form-content">
                <el-tree
                        ref="tree"
                        node-key="id"
                        style="margin-bottom: 20px;"
                        :default-checked-keys="menuIds"
                        :check-strictly="false"
                        :props="props"
                        :data="authList"
                        show-checkbox
                        @check-change="handleCheckChange">
                </el-tree>
                <div class="fill-btn" v-load="editing" @click="setRolePermission">保存</div>
            </div>
        </HgDialog>
    </div>
</template>

<script>
    import {delToast,formVerify} from './../../utlis/decorators'

    export default {
        name: "roleManage",
        data() {
            return {
                loading: false,
                editing: false,
                showAdd: false,
                showEdit: false,
                giveAuth: false,
                menuIds:[],
                searchForm: {
                    page: 1,
                    limit: 10,
                    name: ''
                },
                total: 0,
                authList: [],
                props:{
                    label: 'name',
                    children: 'children'
                },
                tableData: [],
                form: {
                    name: ''
                },
                currentId: '',
                menu_ids:{}
            }
        },
        created() {
            this.getRoleList();
            this.getPermissionList();
        },
        watch: {
            'searchForm.page': 'getRoleList'
        },
        computed: {
            getRules() {
                return {
                    name: [{required: true, message: `角色名称不能为空`, trigger: ['blur', 'change']}],
                }
            }
        },
        methods: {
            /**
             * 显示新增角色
             */
            handShowAdd() {
                this.form.name = '';
                this.showAdd = true;
            },
            /**
             * 显示编辑角色
             * @param id
             * @param name
             */
            handShowEdit({id, name}) {
                this.currentId = id;
                this.form.name = name;
                this.showEdit = true;
            },
            /**
             * 显示赋予权限
             * @param id
             */
            handGiveAuth({id,menu}){
                this.currentId = id;
                this.menuIds=[];


                menu.forEach((item, index) => {
                   this.menuIds.push(item.menu_id)
                });
                this.menu_ids =  this.menuIds;
                console.log( '提交'+this.menu_ids);
                this.giveAuth = true;
            },
            /**
             * 搜索
             */
            handSearch() {
                this.searchForm.page = 1;
                this.getRoleList();
            },
            handleCheckChange(){
                this.menu_ids=this.$refs.tree.getCheckedKeys();

            },

            /**
             * 获取权限列表
             * @returns {Promise<void>}
             */
            async getPermissionList(){
                let [e, data] = await this.$api.menuListAll();
                if(e) return;
                if(data.code === 200){
                    this.authList = data.data;
                }
            },
            /**
             * 获取角色列表
             * @returns {Promise<void>}
             */
            async getRoleList() {
                this.loading = true;
                let [e, data] = await this.$api.roleListAll(this.searchForm);
                this.loading = false;
                if (e) return;
                if (data.code === 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                }
            },
            /**
             * 删除权限
             * @returns {Promise<void>}
             */
            @delToast('此操作将永久删除该角色, 是否继续?')
            async roleDel({id}) {
                let [e, data] = await this.$api.delRole({id});
                if (e) return;
                if (data.code === 200) {
                    this.getRoleList();
                    this.$message.success('删除成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            /**
             * 添加权限
             * @returns {Promise<void>}
             */
            @formVerify('editForm')
            async roleAdd() {
                this.editing = true;
                let [e, data] = await this.$api.createRole(this.form);
                this.editing = false;
                if (e) return;
                if (data.code === 200) {
                    this.getRoleList();
                    this.showAdd = false;
                    this.$message.success('添加成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            /**
             * 编辑权限
             * @returns {Promise<void>}
             */
            @formVerify('editForm')
            async roleEdit() {
                this.editing = true;
                let [e, data] = await this.$api.saveRole({
                    id: this.currentId,
                    name: this.form.name
                });
                this.editing = false;
                if (e) return;
                if (data.code === 200) {
                    this.getRoleList();
                    this.showEdit = false;
                    this.$message.success('保存成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            /**
             * 赋予权限
             * @returns {Promise<void>}
             */
            async setRolePermission(){
                this.editing = true;
                let [e, data] = await this.$api.saveRoleMenu({
                    id: this.currentId,
                    menu_ids:this.menu_ids
                });
                this.editing = false;
                if(e) return;
                if(data.code === 200){
                    this.getRoleList();
                    this.giveAuth = false;
                    this.$message.success('保存成功')
                }else {
                    this.$message.warning(`${data.msg}`)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }
</style>
